import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/vsts/work/1/s/src/layouts/mdlayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {/*heading*/}
    <h1>{`heading1`}</h1>
    <h2>{`heading2`}</h2>
    <h3>{`heading3`}</h3>
    <h4>{`heading4`}</h4>
    <h5>{`heading5`}</h5>
    <h6>{`heading6`}</h6>
    {/*unorderedlist*/}
    <ul>
      <li parentName="ul">{`content1`}</li>
      <li parentName="ul">{`content2`}</li>
    </ul>
    {/*orderedlist*/}
    <ol>
      <li parentName="ol">{`content1`}</li>
      <li parentName="ol">{`content2`}</li>
    </ol>
    {/* paragraph */}
    <p>{`this is a paragraph tag`}</p>
    {/* anchor tag */}
    <p><a parentName="p" {...{
        "href": "https://mdxjs.com"
      }}>{`https://mdxjs.com`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com"
      }}>{`GitHub`}</a></p>
    {/* image tag */}
    <p><img parentName="p" {...{
        "src": "https://mdx-logo.now.sh",
        "alt": "alt"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      